<template>
    <Transition
        enter-active-class="transition-all duration-300 fade"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-all duration-300 fade"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-show="lightboxVisible"
            class="fixed inset-0 z-50 flex max-h-full items-center justify-center bg-black/60 p-4 xl:p-16 transition-opacity duration-300"
        >
            <div
                ref="lightboxSwiperContainer"
                class="swiper max-h-full w-full flex-row items-center rounded-2xl"
            >
                <div class="swiper-wrapper"></div>
            </div>

            <button
                ref="lightboxPrevButton"
                class="absolute bottom-0 left-0 top-0 z-50 my-auto h-16 cursor-pointer"
            >
                <IconSvg
                    name="ChevronLeftIcon"
                    class="stroke-white transition-colors duration-300 hover:stroke-secondary md:h-16 md:w-16"
                    :class="{
                        'stroke-primary/10 hover:stroke-secondary/10':
                            isBeginning,
                    }"
                ></IconSvg>
            </button>

            <button
                ref="lightboxNextButton"
                class="absolute bottom-0 right-0 top-0 z-50 my-auto h-16 cursor-pointer"
            >
                <IconSvg
                    name="ChevronRightIcon"
                    class="stroke-white transition-colors duration-300 hover:stroke-secondary md:h-16 md:w-16"
                    :class="{
                        'stroke-primary/10 hover:stroke-secondary/10': isEnd,
                    }"
                ></IconSvg>
            </button>

            <button
                @click="closeLightbox"
                class="absolute right-4 top-4 text-2xl text-white"
            >
                <IconSvg
                    name="CloseIcon"
                    size="lg"
                    class="stroke-white transition-colors duration-300 hover:stroke-secondary md:h-16 md:w-16"
                ></IconSvg>
            </button>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { Ref, ref, onMounted, watch, inject } from "vue";
import IconSvg from "../icons/_IconSvg.vue";
import { Keyboard, Mousewheel, Navigation, Virtual } from "swiper/modules";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";

export type GalleryImage = {
    key: string;
    url: string;
};

const gallery: Ref<Record<string, GalleryImage> | {}> = inject(
    "galleryData",
) as Ref<Record<string, GalleryImage> | {}>;

const lightboxVisible: Ref<boolean> = inject("lightboxVisible") as Ref<boolean>;

const currentSlideIndex: Ref<number> = inject(
    "currentSlideIndex",
) as Ref<number>;

let lightboxSwiperInstance: Swiper | null = null;

const isEnd = ref(false);
const isBeginning = ref(false);
const lightboxSwiperContainer = ref<HTMLElement | null>(null);
const lightboxPrevButton = ref<HTMLElement | null>(null);
const lightboxNextButton = ref<HTMLElement | null>(null);

onMounted(() => {
    if (lightboxSwiperContainer.value) {
        lightboxSwiperInstance = new Swiper(lightboxSwiperContainer.value, {
            modules: [Keyboard, Navigation, Mousewheel, Virtual],
            slidesPerView: 1,
            grabCursor: true,
            mousewheel: {
                enabled: true,
                sensitivity: 1,
            },
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },

            spaceBetween: 10,
            initialSlide: 1,
            centeredSlides: true,
            navigation: {
                prevEl: lightboxPrevButton.value,
                nextEl: lightboxNextButton.value,
            },

            virtual: {
                enabled: true,
                cache: false,
            },

            on: {
                slideChange: function () {
                    isEnd.value = this?.isEnd || false;
                    isBeginning.value = this?.isBeginning || false;
                },
            },
        });
    }

    watch(gallery, (newValue: Record<number, GalleryImage>) => {
        if (newValue && typeof newValue === "object") {
            prepareSlides(newValue);
        }
    });

    watch(lightboxVisible, (newValue) => {
        if (newValue) {
            openLightbox();
        }
    });
});

const prepareSlides = (gallery: Record<number, GalleryImage>) => {
    const imageArray = Object.values(gallery);

    const virtualSlides = imageArray.map(
        (
            image: any,
        ) => `<img src="${image.url}" class="mx-auto h-auto max-h-[calc(100vh-2.5rem)] xl:max-h-[calc(100vh-8rem)] w-auto max-w-[calc(100vw-2.5rem)] xl:max-w-[calc(100vw-8rem)] z-20 rounded-2xl" loading="lazy" />
                <div class="swiper-lazy-preloader z-10 swiper-lazy-preloader-white"></div>`,
    );

    if (lightboxSwiperInstance) {
        lightboxSwiperInstance.virtual.slides = virtualSlides;
        lightboxSwiperInstance.virtual.update(true);
    }
};

const openLightbox = () => {
    document.body.style.overflow = "hidden";

    let slideIndex =
        typeof currentSlideIndex.value === "number"
            ? currentSlideIndex.value
            : parseInt(currentSlideIndex.value, 10);

    if (lightboxSwiperInstance) {
        lightboxSwiperInstance.slideTo(slideIndex);
    }
};

const closeLightbox = () => {
    document.body.style.overflow = "";
    lightboxVisible.value = false;
};
</script>

<style>
.swiper-slide {
    margin: auto;
    display: flex;
    user-select: none;
}
</style>

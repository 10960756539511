<template>
    <div
        v-on-click-outside="closeDropdown"
        ref="togglerRef"
        class="relative cursor-pointer select-none"
        type="button"
        tabindex="0"
        @keyup.enter="isOpen = !isOpen"
        @click="isOpen = !isOpen"
    >
        <div
            type="button"
            class="flex w-full items-center justify-center gap-x-2 overflow-hidden font-semibold text-primary-dark hover:text-accent"
            aria-expanded="true"
            aria-haspopup="true"
        >
            <IconSvg
                name="GlobeIcon"
                size="lg"
                class="fill-accent transition-colors duration-300 hover:cursor-pointer hover:fill-accent md:h-6 md:w-6"
            ></IconSvg>

            <span class="text-lg font-normal uppercase">{{
                languagesSelector.currentLanguageCode
            }}</span>
        </div>

        <Transition
            enter-from-class="opacity-20 -translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            enter-active-class="duration-150 transform origin-top"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-20 -translate-y-1"
            leave-active-class="duration-100 transform origin-bottom"
        >
            <div
                v-show="isOpen"
                class="dropdown absolute left-0 z-10 mt-2 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
            >
                <div class="py-1" role="none">
                    <a
                        v-for="language in languagesSelector.languagesList"
                        :key="language.code"
                        :href="language.url"
                        class="block px-4 py-2 text-sm hover:bg-gray-100/60"
                        :class="[
                            language.code ===
                            languagesSelector.currentLanguageCode
                                ? 'text-accent'
                                : 'text-gray-700',
                        ]"
                        role="menuitem"
                        tabindex="-1"
                        >{{ language.title }}</a
                    >
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import IconSvg from "~/components/icons/_IconSvg.vue";

const isOpen = ref<boolean>();

const togglerRef = ref<HTMLDivElement>();

type LanguageListItem = {
    code: string;
    title: string;
    url: string;
};

export type LanguagesSelector = {
    currentLanguageCode: string;
    languagesList: Record<string, LanguageListItem>;
};

defineProps({
    languagesSelector: {
        type: Object as () => LanguagesSelector,
        default: () => ({}),
    },
});

const closeDropdown = () => {
    isOpen.value = false;
};
</script>

import.meta.glob(["../images/**"]);

import { Ref, createApp, ref } from "vue";
import IconSvg from "~/components/icons/_IconSvg.vue";
import TopMenu from "~/components/layout/top-menu/TopMenu.vue";
import GalleryComponent from "~/components/gallery/GalleryComponent.vue";
import SearchBlock from "~/components/layout/search/SearchBlock.vue";
import MainSlider from "~/components/main-slider/MainSlider.vue";
import InlineSlider from "~/components/inline-slider/InlineSlider.vue";
import GalleryLightbox from "~/components/gallery/GalleryLightbox.vue";

const app = createApp({
    name: "OrthodoxyApp",
});

export type GalleryImage = {
    key: string;
    full: string;
};

export type OpenLightboxFunctionType = (
    index: number,
    gallery: Record<number, GalleryImage>,
) => void;

const galleryData: Ref<Record<string, GalleryImage> | {}> = ref({}) as Ref<
    Record<string, GalleryImage> | {}
>;

const lightboxVisible = ref(false);
const currentSlideIndex = ref(0);

const openLightbox: OpenLightboxFunctionType = (
    index: number,
    gallery: Record<string, GalleryImage>,
) => {
    console.log("TEST 1");
    galleryData.value = gallery;
    currentSlideIndex.value = index;
    lightboxVisible.value = true;
};

app.provide("openLightboxFunction", openLightbox);
app.provide("galleryData", galleryData);
app.provide("lightboxVisible", lightboxVisible);
app.provide("currentSlideIndex", currentSlideIndex);

app.component("icon-svg", IconSvg);
app.component("top-menu", TopMenu);
app.component("search-block", SearchBlock);
app.component("gallery-lightbox", GalleryLightbox);
app.component("gallery-component", GalleryComponent);
app.component("main-slider", MainSlider);
app.component("inline-slider", InlineSlider);

app.mount("#app");

<!-- <template>
    <svg viewBox="0 0 32 32">
        <path
            d="M15.5 2c-8.008 0-14.5 6.492-14.5 14.5s6.492 14.5 14.5 14.5 14.5-6.492 14.5-14.5-6.492-14.5-14.5-14.5zM10.752 3.854c-0.714 1.289-1.559 3.295-2.113 6.131h-4.983c1.551-2.799 4.062-4.993 7.096-6.131zM3.154 10.987h5.316c-0.234 1.468-0.391 3.128-0.415 5.012h-6.060c0.067-1.781 0.468-3.474 1.159-5.012zM1.988 17.001h6.072c0.023 1.893 0.188 3.541 0.422 5.012h-5.29c-0.694-1.543-1.138-3.224-1.204-5.012zM3.67 23.015h4.977c0.559 2.864 1.416 4.867 2.134 6.142-3.046-1.134-5.557-3.336-7.111-6.142zM15.062 30.009c-1.052-0.033-2.067-0.199-3.045-0.46-0.755-1.236-1.736-3.363-2.356-6.534h5.401v6.994zM15.062 22.013h-5.578c-0.234-1.469-0.396-3.119-0.421-5.012h5.998v5.012zM15.062 15.999h-6.004c0.025-1.886 0.183-3.543 0.417-5.012h5.587v5.012zM15.062 9.985h-5.422c0.615-3.148 1.591-5.266 2.344-6.525 0.987-0.266 2.015-0.435 3.078-0.47v6.995zM29.003 15.999h-5.933c-0.025-1.884-0.182-3.544-0.416-5.012h5.172c0.693 1.541 1.108 3.23 1.177 5.012zM27.322 9.985h-4.837c-0.549-2.806-1.382-4.8-2.091-6.090 2.967 1.154 5.402 3.335 6.928 6.090zM16.063 2.989c1.067 0.047 2.102 0.216 3.092 0.493 0.751 1.263 1.72 3.372 2.331 6.503h-5.423v-6.996zM16.063 10.987h5.587c0.234 1.469 0.392 3.126 0.417 5.012h-6.004v-5.012zM16.063 17.001h5.998c-0.023 1.893-0.187 3.543-0.421 5.012h-5.577v-5.012zM16.063 29.991v-6.977h5.402c-0.617 3.152-1.591 5.271-2.343 6.512-0.978 0.272-2.005 0.418-3.059 0.465zM20.367 29.114c0.714-1.276 1.56-3.266 2.112-6.1h4.835c-1.522 2.766-3.967 4.95-6.947 6.1zM27.795 22.013h-5.152c0.234-1.471 0.398-3.119 0.423-5.012h5.927c-0.067 1.787-0.508 3.468-1.198 5.012z"
        ></path>
    </svg>
</template> -->
<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
        <path
            d="M12.9.4C11.9.1 11 0 10 0S8.1.1 7.2.4C3 1.6 0 5.5 0 10c0 5.5 4.5 10 10 10s10-4.5 10-10c0-4.5-3-8.3-7.1-9.6zm-11.8 10h3.5c0 1.2.2 2.3.4 3.3H1.9c-.5-1-.7-2.1-.8-3.3zm9.5-5.5V1.1c.6 0 1.1.1 1.7.3.3.5 1 1.7 1.5 3.6l-3.2-.1zM14 6c.2 1 .3 2.1.3 3.4h-3.8V6H14zM9.5 4.9H6.4c.5-1.8 1.2-3 1.5-3.5.5-.1 1.1-.2 1.6-.2v3.7zm0 1.1v3.4H5.7c0-1.3.1-2.4.3-3.4h3.5zM4.6 9.4H1.1c.1-1.2.4-2.4 1-3.4h2.8c-.2 1-.3 2.1-.3 3.4zm1.1 1.1h3.7v3.3H6.1c-.2-1.1-.3-2.1-.4-3.3zm3.8 4.3v4c-.6 0-1.1-.1-1.7-.3-.2-.3-.9-1.7-1.4-3.8l3.1.1zm1.1 0h3.1c-.5 2.1-1.2 3.4-1.4 3.7-.6.1-1.1.2-1.7.3v-4zm0-1.1v-3.3h3.8c-.1 1.2-.2 2.3-.4 3.3h-3.4zm4.8-3.2h3.5c-.1 1.2-.4 2.3-.8 3.3h-3c.1-1 .3-2.1.3-3.3zm0-1.1c0-1.3-.1-2.4-.3-3.4H18c.5 1 .8 2.2.9 3.4h-3.5zM17.3 5h-2.4c-.3-1.3-.7-2.2-1.1-3 1.4.6 2.6 1.7 3.5 3zM6.2 1.9c-.3.8-.7 1.7-1 3H2.8c.8-1.3 2-2.3 3.4-3zM2.5 14.8h2.7c.3 1.4.7 2.5 1.1 3.3-1.6-.7-2.9-1.9-3.8-3.3zm11.2 3.3c.3-.7.7-1.8 1.1-3.2h2.6c-.9 1.4-2.2 2.5-3.7 3.2z"
        ></path>
    </svg>
</template>

<template>
    <div ref="swiperContainer" class="relative w-full pb-10 custom-container">
        <div
            class="swiper-wrapper flex flex-row content-stretch items-stretch justify-between"
        >
            <slot name="slides"></slot>
            <!-- <div
                class="swiper-slide"
                v-for="(slide, index) in slides"
                :key="index"
            >
                <a
                    :href="slide.url"
                    class="relative block h-full cursor-pointer overflow-hidden bg-gardenia transition-all duration-200"
                >
                    <div
                        class="relative flex h-full flex-row flex-wrap items-center px-4 py-4"
                    >
                        <h2
                            class="text-xl w-full text-center font-secondary-heading font-light leading-none tracking-tight text-black/60"
                        >
                            {{ slide.title }}
                        </h2>
                    </div>
                </a>
            </div> -->
        </div>

        <div
            ref="pagination"
            class="swiper-pagination bottom-0 left-0 right-0 mx-auto flex w-full flex-row justify-center gap-3"
        ></div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { Pagination, Autoplay, FreeMode } from "swiper/modules";
import Swiper from "swiper";

import "swiper/swiper-bundle.css";

// type Slide = {
//     title: string;
//     url: string;
// };

// defineProps({
//     slides: {
//         type: Object as () => Record<number, Slide>,
//         default: () => ({}),
//     },
// });

const swiperContainer = ref<HTMLElement | null>(null);
const prevButton = ref(null);
const nextButton = ref(null);

const pagination = ref(null);

onMounted(() => {
    if (swiperContainer.value) {
        new Swiper(swiperContainer.value, {
            modules: [Pagination, Autoplay, FreeMode],
            loop: true,
            effect: 'slide',
            autoplay: {
                delay: 3000,
                // disableOnInteraction: true,
            },

            parallax: true,

            freeMode: true,
            speed: 1500,
            grabCursor: true,
            slidesPerView: 1,
            spaceBetween: 50,

            centeredSlides: true,
            centeredSlidesBounds: true,

            navigation: {
                prevEl: prevButton.value,
                nextEl: nextButton.value,
            },

            pagination: {
                el: pagination.value,
                clickable: true,
                renderBullet: function (index, className) {
                    console.log(index, className);
                    return `<span class="swiper-pagination-bullet inline-slider-pagination-bullet w-12 h-4"></span>`;
                },
            },

            // breakpoints: {
            //     "@0.00": {
            //         slidesPerView: 1,
            //         spaceBetween: 10,
            //     },
            //     "@0.75": {
            //         slidesPerView: 1,
            //         spaceBetween: 20,
            //     },
            //     "@1.00": {
            //         slidesPerView: 1,
            //         spaceBetween: 40,
            //     },
            //     "@1.50": {
            //         slidesPerView: 1,
            //         spaceBetween: 50,
            //     },
            // },
        });
    }
});
</script>

<style>
.custom-container .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
}
</style>

<template>
    <div ref="swiperContainer" class="swiper max-w-6xl">
        <div class="swiper-wrapper pb-8">
            <slot name="slides"> </slot>
        </div>

        <div
            ref="pagination"
            class="swiper-pagination -bottom-0 left-0 right-0 mx-auto flex w-full flex-row justify-center gap-3"
        ></div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
// import EducationalSlide from "~/components/main-slider/EducationalSlide.vue";
import { Pagination, Autoplay } from "swiper/modules";
import Swiper from "swiper";

import "swiper/swiper-bundle.css";

// type Slide = {
//     title: string;
//     content: string;
//     photo: string;
// };

// defineProps({
//     slides: {
//         type: Object as () => Record<number, Slide>,
//         default: () => ({}),
//     },
// });

const swiperContainer = ref<HTMLElement | null>(null);
const prevButton = ref(null);
const nextButton = ref(null);

const pagination = ref(null);

onMounted(() => {
    if (swiperContainer.value) {
        new Swiper(swiperContainer.value, {
            modules: [Pagination, Autoplay],
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },

            grabCursor: true,
            slidesPerView: 1,
            spaceBetween: 50,

            navigation: {
                prevEl: prevButton.value,
                nextEl: nextButton.value,
            },

            pagination: {
                el: pagination.value,
                clickable: true,
                renderBullet: function (index, className) {
                    console.log(index, className);
                    return `<span class="swiper-pagination-bullet main-slider-pagination-bullet w-12 h-4"></span>`;
                },
            },
        });
    }
});
</script>

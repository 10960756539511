<template>
    <div
        class="relative flex items-start overflow-hidden"
        :class="defaultClass['box']"
    >
        <div
            class="hamburger-menu relative w-full bg-accent outline-none transition-all duration-200"
            :class="[
                defaultClass['hamburger-menu'],
                menuOpen ? 'animate bg-accent/0' : '',
            ]"
            tabindex="0"
        >
            <div
                class="hamburger-menu-after absolute w-full bg-accent"
                :class="defaultClass['hamburger-menu-after']"
            ></div>

            <div
                class="hamburger-menu-before absolute w-full bg-accent"
                :class="defaultClass['hamburger-menu-before']"
            ></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
    size: {
        type: String,
        default: "md",
    },
    desktopSize: {
        type: String,
        default: "xl",
    },

    menuOpen: {
        type: Boolean,
        default: false,
    },
});

type ClassType = {
    box: string;
    "hamburger-menu": string;
    "hamburger-menu-before": string;
    "hamburger-menu-after": string;
};

const classes: Record<string, ClassType> = {
    xs: {
        box: "h-3 w-3",
        "hamburger-menu": "translate-y-1.5 h-[1px]",
        "hamburger-menu-before": "bottom-1 h-[1px]",
        "hamburger-menu-after": "top-1 h-[1px]",
    },
    sm: {
        box: "h-4 w-4",
        "hamburger-menu": "translate-y-2 h-[1px]",
        "hamburger-menu-before": "bottom-1.5 h-[1px]",
        "hamburger-menu-after": "top-1.5 h-[1px]",
    },
    md: {
        box: "h-5 w-5",
        "hamburger-menu": "translate-y-2.5 h-[2px]",
        "hamburger-menu-before": "bottom-2 h-[2px]",
        "hamburger-menu-after": "top-2 h-[2px]",
    },
    lg: {
        box: "h-6 w-6",
        "hamburger-menu": "translate-y-3 h-[2px]",
        "hamburger-menu-before": "bottom-2.5 h-[2px]",
        "hamburger-menu-after": "top-2.5 h-[2px]",
    },
    xl: {
        box: "h-6 w-6 md:h-7 md:w-7",
        "hamburger-menu": "translate-y-3 h-[2px] md:translate-y-3.5 md:h-[2px]",
        "hamburger-menu-before": "bottom-2.5 h-[2px] md:bottom-3 md:h-[2px]",
        "hamburger-menu-after": "top-2.5 h-[2px] md:top-3 md:h-[2px]",
    },
};

const defaultClass = computed((): ClassType => {
    return <ClassType>classes[props.size];
});
</script>

<style lang="scss" scoped>
.hamburger-menu-before {
    transition:
        bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu-after {
    transition:
        top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate .hamburger-menu-after {
    top: 0;
    transform: rotate(45deg);
    transition:
        top 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate .hamburger-menu-before {
    bottom: 0;
    transform: rotate(-45deg);
    transition:
        bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
</style>

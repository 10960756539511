<template>
    <div
        @click="isOpen = true"
        class="relative mx-auto flex w-full max-w-screen-md cursor-pointer justify-center px-4"
    >
        <div class="relative w-full bg-gray-50 py-1 md:py-6">
            <div
                class="flex flex-col content-center items-center justify-between border-b-0 px-4 text-center"
            >
                <div
                    class="cursor text-md flex flex-row items-center font-heading text-gray-400 md:text-xl"
                >
                    {{ texts.search }}
                    <i class="bg-gray-300"></i>
                </div>
            </div>
        </div>
    </div>

    <Transition
        enter-active-class="transition-all duration-300 fade"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-all duration-300 fade"
        leave-from-class="opacity-100 "
        leave-to-class="opacity-0"
    >
        <div
            v-if="isOpen"
            class="fixed top-0 z-50 flex h-full w-full flex-col px-4 py-16 backdrop-blur-sm backdrop-brightness-95"
        >
            <div
                class="relative mx-auto mt-32 flex h-auto md:h-[200px] py-8 w-full max-w-screen-lg flex-col rounded-xl bg-white px-8 shadow-2xl"
            >
                <div
                    @click="isOpen = false"
                    class="absolute -right-0 -top-16 font-heading text-2xl text-primary-dark hover:cursor-pointer hover:text-gray-600"
                >
                    {{ texts.close }}
                </div>

                <input
                    type="text"
                    ref="searchInput"
                    name="search"
                    placeholder="Поиск по сайту"
                    class="font-category block w-full rounded-none border-b-[1px] border-gray-400 bg-transparent pb-4 text-left text-3xl font-bold text-primary-dark ring-0 placeholder:text-gray-600 focus:outline-none focus:ring-0"
                />

                <div class="mt-4 flex flex-row flex-wrap gap-4">
                    <a
                        href="#"
                        class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-sm font-medium text-gray-600 hover:bg-gray-100"
                        >Митрополит</a
                    >
                    <a
                        href="#"
                        class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-sm font-medium text-gray-600 hover:bg-gray-100"
                        >Пречистенский собор</a
                    >
                    <a
                        href="#"
                        class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-sm font-medium text-gray-600 hover:bg-gray-100"
                        >Иларион</a
                    >
                    <a
                        href="#"
                        class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-sm font-medium text-gray-600 hover:bg-gray-100"
                        >Расписание</a
                    >
                    <a
                        href="#"
                        class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-sm font-medium text-gray-600 hover:bg-gray-100"
                        >Служение владыки</a
                    >
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { ref, watch, nextTick, onMounted, onUnmounted } from "vue";

const isOpen = ref(false);

const searchInput = ref<HTMLDivElement>();

onMounted(() => {
    window.addEventListener("keydown", handleEscape);
});

onUnmounted(() => {
    window.removeEventListener("keydown", handleEscape);
});

type LanguageListItem = {
    language: string;
    title: string;
    url: string;
};

export type LanguagesSelector = {
    currentLanguage: string;
    languagesList: Record<string, LanguageListItem>;
};

const handleEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
        isOpen.value = false;
    }
};

type Search = {
    search: string;
    close: string;
};

defineProps({
    texts: {
        type: Object as () => Search,
        default: () => ({}),
    },
});

watch(isOpen, (newVal) => {
    if (newVal) {
        // Using nextTick to ensure DOM has been updated
        nextTick(() => {
            searchInput.value?.focus();
        });
    }
});
</script>

<style>
.cursor i {
    display: inline-block;
    width: 2px;
    height: 1.5em;
    vertical-align: middle;
    margin-left: 2px;
    animation: blink 1s step-end infinite;
}

@keyframes blink {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
</style>

<template>
    <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div
            v-for="(image, index) in gallery"
            @click="handleImageClick(index)"
            :key="`gallery_${image.key}`"
            class="cursor-pointer"
        >
            <img
                class="h-auto max-w-full rounded-lg"
                :src="`${image.url}?width=400&height=250`"
                alt=""
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { inject } from "vue";
import "swiper/swiper-bundle.css";

export type GalleryImage = {
    key: string;
    url: string;
};

export type OpenLightboxFunctionType = (
    index: number,
    gallery: Record<number, GalleryImage>,
) => void;

const props = defineProps({
    gallery: {
        type: Object as () => Record<number, GalleryImage>,
        default: () => ({}),
    },
});

const openLightbox = inject("openLightboxFunction") as OpenLightboxFunctionType;

const handleImageClick = (index: number) => {
    openLightbox(index, props.gallery);
};
</script>

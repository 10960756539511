<template>
    <nav v-on-click-outside="closeMenu" class="relative isolate z-50">
        <div class="relative">
            <div
                class="flex w-full flex-col justify-center bg-white"
                :class="menuClasses.menuRow"
            >
                <div
                    class="mx-auto container px-4 sm:px-6 lg:px-8 flex flex-row flex-wrap items-center justify-between gap-6 sm:flex-nowrap sm:gap-0"
                >
                    <div
                        class="order-1 flex w-full justify-center gap-x-12 md:justify-between sm:w-auto sm:gap-12 lg:gap-6"
                    >
                        <slot name="socials"> </slot>
                    </div>

                    <div
                        class="order-2 hidden max-w-[768px] flex-col px-12 text-center text-slate-900 lg:flex"
                    >
                        <slot name="announcement"> </slot>
                    </div>

                    <div
                        class="order-3 flex w-full flex-row justify-between gap-0 sm:w-auto sm:gap-12 lg:gap-6"
                    >
                        <div class="order-2 sm:order-1">
                            <button
                                @click="toggleMenu"
                                class="relative flex flex-row items-center gap-x-2"
                                :aria-expanded="menuOpen"
                            >
                                <MenuHamburger
                                    size="xl"
                                    :menu-open="menuOpen"
                                />

                                <span
                                    class="text-xl font-thin uppercase hover:text-accent"
                                    >{{ texts.menu }}</span
                                >
                            </button>
                        </div>

                        <div class="order-1 sm:order-2">
                            <LanguagesSelector
                                :languages-selector="languagesSelector"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Transition
                enter-active-class="transition-all duration-300 ease-in-out"
                enter-from-class="opacity-0 -translate-y-full"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition-all duration-300 ease-in-out"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 -translate-y-full"
            >
                <div
                    v-show="menuOpen"
                    ref="menuElement"
                    class="absolute flex flex-col justify-between inset-x-0 -z-10 overflow-scroll bg-white shadow-lg ring-1 ring-gray-900/5"
                    :class="menuClasses.menuElement"
                >
                    <div
                        class="order-1 flex w-full flex-col bg-gray-100 p-4 px-2 text-center text-slate-900 lg:hidden"
                    >
                        <slot name="announcement"> </slot>
                    </div>

                    <slot name="content"> </slot>
                </div>
            </Transition>
        </div>
    </nav>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";
// import { useEventListener } from "@vueuse/core";
import { vOnClickOutside } from "@vueuse/components";
import { setBodyClass, unsetBodyClass } from "~/composables/useBodyClass";
import MenuHamburger from "~/components/layout/top-menu/MenuHamburger.vue";
import LanguagesSelector, {
    LanguagesSelector as LanguagesSelectorType,
} from "~/components/layout/top-menu/LanguagesSelector.vue";

defineProps({
    texts: {
        type: Object as () => Record<string, string>,
        default: () => ({}),
    },
    languagesSelector: {
        type: Object as () => LanguagesSelectorType,
        default: () => ({}),
    },
});

const menuClasses = {
    menuRow: "h-[133px] sm:h-[90px]",
    menuElement:
        "top-[133px] h-[calc(100vh-133px)] sm:top-[90px] sm:h-[calc(100vh-90px)]",
};

const menuElement = ref<HTMLElement | null>(null);

const menuOpen = ref(false);

function toggleMenu(): void {
    menuOpen.value = !menuOpen.value;

    if (menuOpen.value) {
        setBodyClass("overflow-hidden");
        // setBodyClass("xl:overflow-scroll");
    } else {
        unsetBodyClass("overflow-hidden");
        // unsetBodyClass("xl:overflow-scroll");
    }
}

const closeMenu = () => {
    menuOpen.value = false;
};

// useEventListener("scroll", () => {
//     closeMenu();
// });

onMounted(() => {
    window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", handleScroll);
});

const handleScroll = (e: Event) => {
    const target = e.target as HTMLElement;

    if (menuElement.value && !menuElement.value.contains(target)) {
        closeMenu();
    }
};
</script>
